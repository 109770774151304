import { Room } from "colyseus.js";
import { k } from "../main";
import type { MyRoomState } from "../../Server/src/rooms/schema/MyRoomState"
import { getTeamColor } from "./gameplay";

// Define the function to create the menu scene
export function createScreenGameOverScene() {
	k.scene("screen-game-over-scene", (room: Room<MyRoomState>, message) => {
		k.setBackground(40, 30, 90);

		let winnerId = message.winnerId;
		let killfeeds: [string | undefined, string][] = message.killfeeds;

		// Center text to show winner team
		k.add([
			k.text(winnerId != -1 ? `TEAM ${getTeamColor(winnerId)} WIN!` : "TIE", {size: 60}),
			k.pos(k.center()),
			k.anchor("center"),
		]);

		// Bottom text to show host restart prompt
        k.add([
			k.text("WAITING FOR HOST TO RESTART", {size: 22}),
			k.pos(k.width() / 2, k.height() - 100),
			k.anchor("center"),
		]);

		// Top right corner text to show killfeeds
		console.log(killfeeds);
		let index = 0;
		for (let killfeed of killfeeds) {
			let killer = killfeed[0];
			let victim = killfeed[1];

			k.add([
				k.text(killer != null ? `${killer} killed ${victim}` : `${victim} crashed`, {size: 22}),
				k.pos(k.width() * 0.9, k.height() * 0.1 + (26 * index++)),
				k.anchor("right"),
			]);
		}

		// Auto restart timer
		var timer = 5;
		function startRestartTimer() {
			setTimeout(() => {
				timer -= 1;
	
				if (timer >= 1) {
					startRestartTimer();
				} else {
					room.send("restart");
				}
			}, 1000);
		}

		startRestartTimer();

		// Bottom text to show auto restart timer
		k.onDraw(() => {
			k.drawText({
				text: timer >= 1 ? `(auto restart in ${timer}s)` : "RESTARTING ...",
				size: 18,
				width: k.width(),
				align: "center",
				font: "sans-serif",
				pos: k.vec2(0, k.height() - 60),
				color: k.WHITE
			});
		});

		// Restart on mouse press anywhere on screen
		k.onMousePress(() => {
			room.send("restart");
		})

		// When received restart message, we restart
        room.onMessage("restart", () => {
			room.removeAllListeners();
            k.go("host-waiting", room);
        })
	});
}
import { GameObj } from "kaboom";
import { MyRoomState } from "../../Server/src/rooms/schema/MyRoomState";
import { k, client } from "../main";

// Define the function to create the menu scene
export function createMenuScene() {
	k.scene("menu", () => {
		loadScreenAssets();

		location.hash = "";

		// Add background
		addParallaxBackground();

		// Add logo
		k.add([
			k.sprite("logo"),
			k.pos(k.width() / 2, k.height() * 0.2),
			k.anchor("center"),
			k.area(),
			"host-btn"
		]);

		// Add host button
		k.add([
			k.sprite("button"),
			k.pos(k.center()),
			k.anchor("center"),
			k.area(),
			"host-btn"
		]);

		const hostText = k.add([
			k.text("HOST", {size: 60}),
			k.pos(k.center()),
			k.anchor("center"),
		]);
		
		const clickListener = k.onClick("host-btn", () => {
			clickListener.cancel();
			hostText.text = "Connecting ...";
			k.play("destroyed");

			client.create<MyRoomState>("my_room").then(room => {
				console.log(room.sessionId, "joined", room.name, room.id);
				k.play("bg-music", { volume: 0.3, loop: true });
				k.go("host-waiting", room);
			}).catch(e => {
				console.log("JOIN ERROR", e);
			});
		});

		// Credits Text: Bernard
		k.add([
			k.text("Made by FireredPlays", {size: 42}),
			k.pos(k.width() / 2, k.height() * 0.8),
			k.anchor("center"),
			k.color(k.BLUE),
			k.area(),
			"credit-text-fireredplays"
		]);

		k.onClick("credit-text-fireredplays", () => {
			window.open('https://www.youtube.com/fireredplays', '_blank').focus();
		});

		// Credits Text: Graphics
		k.add([
			k.text("Graphics by GameDeveloperStudio", {size: 32}),
			k.pos(k.width() / 2, k.height() * 0.8 + 42),
			k.anchor("center"),
			k.color(k.BLUE),
			k.area(),
			"credit-text-graphics"
		]);

		k.onClick("credit-text-graphics", () => {
			window.open('https://www.gamedeveloperstudio.com/', '_blank').focus();
		})

		// Credits Text: Music
		k.add([
			k.text("Music by Clement Panchout", {size: 32}),
			k.pos(k.width() / 2, k.height() * 0.8 + (42 * 2)),
			k.anchor("center"),
			k.color(k.BLUE),
			k.area(),
			"credit-text-clement"
		]);

		k.onClick("credit-text-clement", () => {
			window.open('https://www.clementpanchout.com', '_blank').focus();
		})
	});
}

function loadScreenAssets() {
	// Load background
	k.loadSprite("cloud-bg", require('../assets/mid_ground_cloud_2-min.png'));
	k.loadSprite("midground_mountains", require('../assets/midground_mountains-min.png'));
	k.loadSprite("mid_ground_cloud_1", require('../assets/mid_ground_cloud_1-min.png'));
	k.loadSprite("farground_cloud_1", require('../assets/farground_cloud_1.png'));
	
	// Load bullet
	k.loadSprite("bullet1", require('../assets/torpedo.png'))

	// Load planes
	k.loadSprite("plane_1_Blue", require('../assets/plane_1_blue.png'));
	k.loadSprite("plane_2_Blue", require('../assets/plane_2_blue.png'));
	k.loadSprite("plane_3_Blue", require('../assets/plane_3_blue.png'));
	k.loadSprite("plane_1_Red", require('../assets/plane_1_red.png'));
	k.loadSprite("plane_2_Red", require('../assets/plane_2_red.png'));
	k.loadSprite("plane_3_Red", require('../assets/plane_3_red.png'));

	// Load explosion spritesheet
	k.loadSprite("explosion", require('../assets/explosion.png'), {
		sliceX: 3,
		sliceY: 3,
		anims: {
			explode: {
				from: 0,
				to: 8,
				speed: 24
			},
		},
	});
	
	// Load smoke effects
	let smokeOpt = {
		sliceX: 5,
		sliceY: 3,
		anims: {
			explode: {
				from: 0,
				to: 14,
				speed: 24,
				loop: true
			},
		},
	}

	k.loadSprite("white-smoke", require('../assets/Smoke15Frames_White.png'), smokeOpt);
	k.loadSprite("black-smoke", require('../assets/Smoke15Frames_Black.png'), smokeOpt);

	// Load required sounds
	k.loadSound("destroyed", require('url:../assets/sounds/click.mp3'));
	k.loadSound("player-joined", require('url:../assets/sounds/joined.mp3'));
	k.loadSound("bg-music", require('url:../assets/sounds/background.mp3'));

	// Load logo
	k.loadSprite("logo", require('../assets/logo.png'));
}

export function addParallaxBackground() {
	const SPEED = 100;

	k.setBackground(65, 217, 255);

	k.add([
		k.sprite("farground_cloud_1"),
		k.pos(0, 40),
		k.z(0)
	]);

	// Mid Cloud
	const midGroundCloud1 = k.add([
		k.sprite("mid_ground_cloud_1"),
		k.pos(0, k.height() - 900),
		k.move(0, -SPEED),
		k.z(0)
	]);

	const midGroundCloud2 = k.add([
		k.sprite("mid_ground_cloud_1"),
		k.pos(2048, k.height() - 900),
		k.move(0, -SPEED),
		k.z(0)
	]);

	midGroundCloud1.onUpdate(midResetBg.bind(midGroundCloud1));
	midGroundCloud2.onUpdate(midResetBg.bind(midGroundCloud2));

	function midResetBg() {
		if (this.pos.x <= -2048) {
			this.pos.x = k.width();
		}
	}

	// Top cloud
	const cloud1 = k.add([
		k.sprite("cloud-bg"),
		k.pos(0, k.height() - 474),
		k.move(0, SPEED),
		k.z(0)
	]);

	const cloud2 = k.add([
		k.sprite("cloud-bg"),
		k.pos(-2048, k.height() - 474),
		k.move(0, SPEED),
		k.z(0)
	]);

	k.add([
		k.sprite("midground_mountains"),
		k.pos(0, k.height() - 119),
		k.z(0)
	])

	cloud1.onUpdate(resetBg.bind(cloud1));
	cloud2.onUpdate(resetBg.bind(cloud2));

	function resetBg() {
		if (this.pos.x > k.width()) {
			this.pos.x = -2048;
		}
	}
}
import { Room } from "colyseus.js";
import { k } from "../main";
import type { MyRoomState } from "../../Server/src/rooms/schema/MyRoomState"
import { Vec2 } from "kaboom";

// Define the function to create the menu scene
export function createControllerScene() {
	let currentMovement = 0;
	let isShooting = false;

	k.scene("controller", (room: Room<MyRoomState>) => {
		k.setBackground(40, 30, 90);
		let gameStarted = false;
		let player = room.state.players.get(room.sessionId);
		let playerName = player != null ? player.name : room.sessionId;

		// Add ready button
		k.add([
			k.sprite("button"),
			k.pos(k.center()),
			k.anchor("center"),
			k.area(),
			"ready-btn", // for click event
			"ready-ui", // for when game is started
		]);
	
		const readyText = k.add([
			k.text(player != null && player.isHost ? "START" : "READY", {size: 60}),
			k.pos(k.center()),
			k.anchor("center"),
			k.area(),
			"ready-ui",
		]);
		
		k.onClick("ready-btn", () => {
			if (!player) return;

			// If player, we switch from ready to unready.
			if (!player.isHost) {
				room.send("ready", readyText.text == "READY");
			}

			// If is host, we check if all player are ready
			else {
				for (const playerId of room.state.players) {
					const player = room.state.players[playerId[0]];

					if (!player.ready) {
						alert("Not all players ready!");
						return;
					}
				}

				// All player ready, start the game
				room.send("start-game");
			}
		})

		// Keep updating player state
		room.onStateChange((_state) => {
			player = _state.players.get(room.sessionId);

			if (!player) return;

			// Update readyText based on player.ready
			readyText.text = player.ready ? "UNREADY" : "READY";
			
			// If is host, and text is default ("READY"/"UNREADY"), then we need to change to "START"
			if (player.isHost && readyText.text != "START") {
				readyText.text = "START";
			}
		});

		room.onMessage("start-game", () => {
			k.destroyAll("ready-ui")
			gameStarted = true;

			room.onMessage("controller-game-over", (winnerId = null) => {
				k.go("controller-game-over-scene", room, winnerId);
			})
		});
	
		// Add rename button
		k.add([
			k.sprite("button"),
			k.pos(k.width() / 2, k.height() / 2 + 200),
			k.anchor("center"),
			k.area(),
			"rename-btn",
			"ready-ui"
		]);
	
		k.add([
			k.text("RENAME", {size: 60}),
			k.pos(k.width() / 2, k.height() / 2 + 200),
			k.anchor("center"),
			"ready-ui"
		]);
		
		k.onClick("rename-btn", () => {
			let enteredName = prompt("Enter your username:", playerName);
	
			if (enteredName != null && enteredName != "") {
				playerName = enteredName;
				room.send("rename", playerName);
			}
		});

		// Add random button
		k.add([
			k.sprite("button"),
			k.pos(k.width() / 2, k.height() / 2 + 400),
			k.anchor("center"),
			k.area(),
			"random-btn",
			"ready-ui"
		]);

		k.add([
			k.text("RANDOMIZE TEAM", {size: 60}),
			k.pos(k.width() / 2, k.height() / 2 + 400),
			k.anchor("center"),
			"ready-ui"
		]);

		k.onClick("random-btn", () => {
			room.send("randomize-team");
		});
	
		// Draw button and textbox
		k.onDraw(() => {
			// If game has started, draw the controller
			if (gameStarted && player != null) {
				// Up button
				k.drawRect({
					width: k.width() / 2,
					height: k.height() / 2,
					pos: k.vec2(0, 0),
					color: currentMovement == 1 ? k.CYAN : k.YELLOW,
					outline: { color: k.BLACK, width: 2 },
				});
	
				k.drawText({
					text: "UP",
					size: 48,
					width: k.width() / 2,
					align: "center",
					font: "sans-serif",
					pos: k.vec2(0, k.height() * 0.25),
					color: k.BLACK
				});
	
				// Down button
				k.drawRect({
					width: k.width() / 2,
					height: k.height() / 2,
					pos: k.vec2(0, k.height() / 2),
					color: currentMovement == -1 ? k.CYAN : k.YELLOW,
					outline: { color: k.BLACK, width: 2 },
				});
	
				k.drawText({
					text: "DOWN",
					size: 48,
					width: k.width() / 2,
					align: "center",
					font: "sans-serif",
					pos: k.vec2(0, k.height() * 0.75),
					color: k.BLACK
				});

				// Shoot Button
				k.drawRect({
					width: k.width() / 2,
					height: k.height() / 2,
					pos: k.vec2(k.width() / 2, k.height() / 2),
					color: isShooting ? k.CYAN : k.YELLOW,
					outline: { color: k.BLACK, width: 2 },
				});

				k.drawText({
					text: player.reloadTimer == 0 ? `SHOOT\n(${player.bulletLeft}/20)` : (3000 - player.reloadTimer) / 1000 + "s",
					size: 32,
					width: k.width() / 2,
					align: "center",
					font: "sans-serif",
					pos: k.vec2(k.width() / 2, k.height() * 0.75),
					color: k.BLACK
				});
			}

			// If we're on waiting screen, we draw the username box and username
			else {
				k.drawRect({
					width: k.width() * 0.6,
					height: 100,
					pos: k.vec2(k.width() / 2 - (k.width() * 0.6 / 2), k.height() / 2 - 250),
					outline: { color: k.BLACK, width: 2 },
				});
	
				k.drawText({
					text: playerName,
					size: 48,
					width: k.width() / 2,
					align: "left",
					font: "sans-serif",
					pos: k.vec2(k.width() / 2 - (k.width() * 0.6 / 2) + 10, k.height() / 2 - 220),
					color: k.BLACK
				})
			}
		});
	
		// Input Events
		k.onTouchStart((pos, t) => {
			onClick(pos);
		});
	
		k.onTouchEnd((pos, t) => {
			onUnclick(pos);
		});

		k.onMousePress((m) => {
			onClick(k.mousePos());
		})

		k.onMouseRelease((m) => {
			onUnclick(k.mousePos());
		})

		function onClick(pos: Vec2) {
			if (!gameStarted) return;

			console.log("shoot once");

			// If it is on the left side, means it's up or down
			if (pos.x < k.width() / 2) {
				if (pos.y < k.height() / 2) {
					currentMovement = 1;
				}
				else {
					currentMovement = -1;
				}

				room.send("movement", currentMovement);
			}
			
			// Click on right side, only shooting
			else {
				if (pos.y > k.height() / 2) {
					isShooting = true;
				}

				room.send("isShooting", isShooting);
			}
		}
	
		function onUnclick(pos) {
			if (!gameStarted) return;

			// Unclick on left side is always movement stop
			if (pos.x < k.width() / 2) {
				currentMovement = 0;
				room.send("movement", 0);
			}
			else {
				// Bottom right is stop shooting
				if (pos.y > k.height() / 2) {
					isShooting = false;
					room.send("isShooting", false);
				}
			}
		}
	});
}
import kaboom from 'kaboom'
import * as Colyseus from "colyseus.js";
import type { MyRoomState } from "../Server/src/rooms/schema/MyRoomState"
import { createMenuScene } from "./scenes/menu"
import { createHostWaitingScene } from "./scenes/host-waiting"
import { createControllerScene } from './scenes/controller';
import { createControllerGameOverScene } from './scenes/controller-game-over';
import { createGameplayScene } from './scenes/gameplay';
import { createScreenGameOverScene } from './scenes/screen-game-over';

// Initialize Kaboom
export const k = kaboom({
	background: "41D9FF"
});

// Load button sprite
k.loadSprite("button", require('./assets/button.png'));

// Connect to server
export var client = new Colyseus.Client({ hostname: "server.airparty.app", secure: true }); // { hostname: "server.airparty.app", secure: true }

// Create scenes
createMenuScene();
createHostWaitingScene();
createControllerScene();
createControllerGameOverScene();
createGameplayScene();
createScreenGameOverScene();

// Only allow running on whitelisted domain
var whitelistedDomains = ["192.168.1.39:1234", "127.0.0.1:1234", "airparty.app"];
if (whitelistedDomains.includes(window.location.host)) {
	if (location.hash != "") {
		client.joinById<MyRoomState>(location.hash.substring(1)).then(room => {
			k.go("controller", room);
		}).catch(e => {
			location.hash = "";
			location.reload();
		});
	}
	else {
		k.go("menu");
	}
}
import { k } from "../main";
import { GameObj, Vec2 } from 'kaboom'
import { Room } from "colyseus.js";
import type { MyRoomState } from "../../Server/src/rooms/schema/MyRoomState"
import { addParallaxBackground } from "./menu";

const PLAYER_MAX_HEALTH = 30;
const BULLET_INTERVAL = 0.2;

interface PlayerData {
    playerSprite: GameObj;
    playerText: GameObj;
    movement: number;
    isShooting: boolean;
    bulletTimer: number;
    teamId: number;
}

let killfeeds: [string | undefined, string][] = [];

// Define the function to create the menu scene
export function createGameplayScene() {
    let players: { [key: string]: PlayerData } = {};
    killfeeds = [];

	k.scene("gameplay", (room: Room<MyRoomState>) => {
        // Reset player
        players = {};

		// Add background
		addParallaxBackground();

        // Spawn player
        let indexTeam0 = 0;
		let indexTeam1 = 0;
        room.state.players.forEach((player, sessionId) => {
            // Add player plane
            const playerSprite = k.add([
                // List of components, each offers a set of functionalities
                k.sprite(`plane_${player.spriteId}_${getTeamColor(player.teamId)}`, { flipX: player.teamId != 0}),
				player.teamId == 0 ? k.pos(100, 200 + (150 * indexTeam0++)) : k.pos(k.width() - 100, 200 + (150 * indexTeam1++)),
                k.area(),
                k.health(PLAYER_MAX_HEALTH),
                k.anchor("center"),
                k.rotate(0),
                k.z(2),
                "player",
                `team${player.teamId}`,
                { 
                    username: player.name,
                    smokeSpawnTimer: 0
                }
            ]);

            // Add player name
            const playerText = playerSprite.add([
                k.text(player.name, {size: 12}),
                k.pos(0, 0),
                k.anchor("center"),
            ]);

            // Set default velocity
            playerSprite.onUpdate(() => {
                let serverPlayer = room.state.players.get(sessionId)
                let localPlayer = players[sessionId];
                const speed = 300;
                const angleSpeed = 90;

                if (serverPlayer == null) return;

                // Move forward constantly
                if (serverPlayer.teamId == 0) {
                    playerSprite.pos = playerSprite.pos.add(k.Vec2.fromAngle(playerSprite.angle).scale(speed * k.dt()));
                } else {
                    playerSprite.pos = playerSprite.pos.sub(k.Vec2.fromAngle(playerSprite.angle).scale(speed * k.dt()));
                }

                // Update angle based on movement
                if (localPlayer.movement != 0) {
                    playerSprite.angle -= angleSpeed * (serverPlayer.teamId == 0 ? localPlayer.movement : -localPlayer.movement) * k.dt();
                }

                // Shooting
                localPlayer.bulletTimer += k.dt();
                if (localPlayer.isShooting && localPlayer.bulletTimer > BULLET_INTERVAL && serverPlayer.bulletLeft > 0) {
                    createProjectile(
                        playerSprite.pos.x,
                        playerSprite.pos.y,
                        serverPlayer.teamId == 0 ? playerSprite.angle : (playerSprite.angle - 180) % 360,
                        serverPlayer.teamId == 0 ? "team1" : "team0",
                        serverPlayer.name
                    );

                    localPlayer.bulletTimer = 0;
                    room.send("minus-bullet", sessionId);
                }

                /* BOUNDS */
                // When reaches end, restart at 0
                if (playerSprite.pos.x > k.width()) {
                    playerSprite.pos.x = 0;
                } else if (playerSprite.pos.x < 0) {
                    playerSprite.pos.x = k.width();
                }

                if (playerSprite.pos.y > k.height() || playerSprite.pos.y < 0) {
                    killfeeds.push([undefined, serverPlayer.name]);
                    addMessage(`${serverPlayer.name} crashed!`);
                    playerSprite.hurt(100);
                }

                /* SMOKE EFFECT appear when HP is low */
                playerSprite.smokeSpawnTimer += k.dt();
                if (playerSprite.hp() <= PLAYER_MAX_HEALTH * 0.5 && playerSprite.smokeSpawnTimer >= 0.05) {
                    playerSprite.smokeSpawnTimer = 0;
                    addSmoke(playerSprite.pos, playerSprite.hp() <= PLAYER_MAX_HEALTH * 0.2 ? "black" : "white");
                }
            });

            playerSprite.on("death", () => {
                room.send("controller-game-over", sessionId);
                addExplosion(playerSprite.pos);
                k.destroy(playerSprite);
                delete players[sessionId];

                // Check if there are still existing plane, if not, we send screen-game-over message.
                let team0Count = countPlayersByTeamId(0);
                let team1Count = countPlayersByTeamId(1);

                if (team0Count == 0 && team1Count == 0) {
                    room.send("screen-game-over", {winnerId: -1, killfeeds: killfeeds});
                }
                else if (team0Count == 0) {
                    room.send("screen-game-over", {winnerId: 1, killfeeds: killfeeds});
                } else if (team1Count == 0) {
                    room.send("screen-game-over", {winnerId: 0, killfeeds: killfeeds});
                }
            });

            // Add to list of player objects
            players[sessionId] = {
                playerSprite: playerSprite,
                playerText: playerText,
                movement: 0,
                isShooting: false,
                bulletTimer: BULLET_INTERVAL,
                teamId: player.teamId
            }
        });

        room.onMessage("movement-change", (message) => {
            let senderId = message.senderId;
            let movement = message.movement;

            players[senderId].movement = movement;
        });

        room.onMessage("shooting-change", (message) => {
            let senderId = message.senderId;
            let isShooting = message.isShooting;

            players[senderId].isShooting = isShooting;
        });

        room.onMessage("screen-game-over", (winnerId) => {
            room.removeAllListeners();
            k.go("screen-game-over-scene", room, winnerId);
        });

        room.onMessage("player-left", (playerId) => {
            players[playerId].playerSprite.hurt(100);
        });
	});

    function countPlayersByTeamId(teamId: number): number {
        return Object.values(players).reduce((count, player) => {
            if (player.teamId === teamId) {
                count++;
            }
            return count;
        }, 0);
    }
}

function createProjectile(x: number, y: number, angle: number, targetTag: string, ownerName: string) {
    const BULLET_SPEED = 2200;
    const projectile = k.add([
		k.sprite("bullet1"),
		k.pos(x, y),
		k.anchor("center"),
		k.area(),
		k.move(angle, BULLET_SPEED),
		k.rotate(angle),
		k.offscreen({ destroy: true }),
        k.z(1)
	]);

    projectile.onCollide(targetTag, (target: GameObj) => {
        const DAMAGE = 1;
        addExplosion(projectile.pos, 0.3);
        
        // Check if target will be destoryed on this shot?
        if (target.hp() <= DAMAGE) {
            killfeeds.push([ownerName, target.username]);
            addMessage(`${ownerName} destroyed ${target.username}'s plane!`)
        }
        
        // Damage target
        target.hurt(DAMAGE);

        // Delete projectile
        k.destroy(projectile);
    })
}

function addMessage(message: string) {
    k.add([
        k.text(message, { size: 48 }),
        k.pos(k.width() / 2, k.height() * 0.15),
        k.anchor("center"),
        k.color(255, 120, 60),
        k.lifespan(2),
        k.move(-90, 60),
    ])
}

export function getTeamColor(teamId: number) {
    return teamId == 0 ? "Blue" : "Red";
}

function addExplosion(pos: Vec2, scale: number = 1) {
    // k.addKaboom(pos);

    const explosive = k.add([
        k.sprite("explosion", {
            anim: "explode"
        }),
        k.pos(pos),
        k.anchor("center"),
        k.scale(scale),
        k.stay(),
    ]);

    explosive.onAnimEnd(() => {
        k.destroy(explosive);
    })
}

function addSmoke(pos: Vec2, color: String) {
    const smoke = k.add([
        k.sprite(`${color}-smoke`, {
            anim: "explode"
        }),
        k.pos(pos.x, pos.y + 10),
        k.anchor("center"),
        k.opacity(1),
        k.scale(0.7),
        k.lifespan(1.2, { fade: 1 }),
    ]);
}
import { Room } from "colyseus.js";
import { k } from "../main";
import type { MyRoomState } from "../../Server/src/rooms/schema/MyRoomState"
import { getTeamColor } from "./gameplay";

// Define the function to create the menu scene
export function createControllerGameOverScene() {
	k.scene("controller-game-over-scene", (room: Room<MyRoomState>, winnerId: number) => {
		k.setBackground(40, 30, 90);

        // If there are still existing players in-game from both team, we show game over only.
        if (winnerId == null) {
            k.add([
                k.text("GAME OVER!", {size: 60}),
                k.pos(k.center()),
                k.anchor("center"),
            ]);
        }

        // Only when the game has ended (only one team is alive, or tie), we show the result.
        else {
            k.add([
                k.text(winnerId != -1 ? `TEAM ${getTeamColor(winnerId)} WIN!` : "TIE", {size: 60}),
                k.pos(k.center()),
                k.anchor("center"),
            ]);

            const player = room.state.players.get(room.sessionId);
            if (player != null && player.isHost) {
                // Add host button
                const restartBtn = k.add([
                    k.sprite("button"),
                    k.pos(k.width() / 2, k.height() * 0.75),
                    k.anchor("center"),
                    k.area(),
                    "restart-btn"
                ]);

                k.add([
                    k.text("RESTART", {size: 60}),
                    k.pos(k.width() / 2, k.height() * 0.75),
                    k.anchor("center"),
                ]);
                
                k.onClick("restart-btn", () => {
                    room.send("restart");
                });
            }

            room.onMessage("restart", () => {
                room.removeAllListeners();
                k.go("controller", room);
            })
        }
	});
}
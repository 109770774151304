import { k } from "../main";
import { getTeamColor } from "./gameplay";
import { addParallaxBackground } from "./menu";

// Define the function to create the menu scene
export function createHostWaitingScene() {
	k.scene("host-waiting", (room) => {
		// Add background
		addParallaxBackground();

		// Set URL hash
		location.hash = room.id;
		showQRCode();

		// Initial show (before new state come)
		showLoadedPlayers(room);

		// Whenever state change
		const controllerStateChange = room.onStateChange((state) => {
			console.log(room.name, "has new state:", state);

			// Remove all rendered player first
			k.destroyAll("waiting-ui");

			// Show loaded players
			showLoadedPlayers(room);
		});

		room.onMessage("start-game", () => {
			controllerStateChange.clear();
			room.removeAllListeners();
			k.go("gameplay", room);
		});
	});
}

function showQRCode() {
	const qrcode = require('yaqrcode');
	
	// Add QR Code
	k.loadSprite("qr_code", qrcode("airparty.app/" + window.location.hash));
		
	k.add([
		k.sprite("qr_code"),
		k.pos(k.center()),
		k.anchor("center"),
		k.scale(0.75, 0.75),
		k.area(),
		"qr_code",
	]);

	k.add([
		k.text("SCAN TO JOIN", {size: 60}),
		k.pos(k.vec2(k.width() / 2, k.height() / 2 - 220)),
		k.anchor("center"),
		k.color(0, 0, 0),
	]);

	// For easier debugging on phone
	k.onClick("qr_code", () => {
		window.open(window.location.href, "mywindow" + Math.random().toString(16).slice(2), "menubar=1,resizable=1,width=360,height=720").focus();
	});
}

let loadedPlayers = 0;
function showLoadedPlayers(room) {
	// Loop through the array of players
	let indexTeam0 = 0;
	let indexTeam1 = 0;
	room.state.players.forEach((player, sessionId) => {
		// Add player plane
		const playerSprite = k.add([
			// List of components, each offers a set of functionalities
			k.sprite(`plane_${player.spriteId}_${getTeamColor(player.teamId)}`, { flipX: player.teamId != 0}),
			player.teamId == 0 ? k.pos(100, 200 + (150 * indexTeam0++)) : k.pos(k.width() - 100, 200 + (150 * indexTeam1++)),
			k.area(),
			k.health(8),
			k.anchor("center"),
			k.rotate(0),
			"waiting-ui"
		]);

		// Add player name
		playerSprite.add([
			k.text(player.name, {size: 12}),
			k.pos(0, 0),
			k.anchor("center"),
			"waiting-ui"
		]);

		// Show ready state
		if (!player.isHost) {
			playerSprite.add([
				k.text(player.ready ? "READY" : "NOT READY", {size: 12}),
				player.ready ? k.color(0, 255, 0) : k.color(255, 0, 0),
				k.pos(0, 20),
				k.anchor("center"),
				"waiting-ui"
			]);
		}
		else {
			playerSprite.add([
				k.text("HOST", {size: 12}),
				k.color(0, 255, 0),
				k.pos(0, 20),
				k.anchor("center"),
				"waiting-ui"
			]);
		}
	});

	// Play sound when new player join
	if (loadedPlayers < indexTeam0 + indexTeam1) {
		k.play("player-joined");
	}

	loadedPlayers = indexTeam0 + indexTeam1;
}